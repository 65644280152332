import { ChainIdEnum } from 'config/constants/network'
import { APIEndpointEnum, USER_LOCKED_WITHDRAW_ERROR_CODE } from 'config/constants/server'
import { Token } from 'config/types'

import { UserBonus } from 'config/types/bonus/userBonus'
import {
  DepositFiatPaymentMethod,
  DepositRolloverBase,
  LockAmountTipInfo,
  TipDetails,
  WithdrawFiatPaymentMethod,
  WithdrawInfo,
} from 'config/types/payment'
import { BetDetails } from 'config/types/profile'
import {
  BonusTransaction,
  FiatPaymentTransaction,
  QueryDepositInfo,
  RewardTransaction,
  SwapTransaction,
  TipTransaction,
  Transaction,
  WithdrawFiatTransaction,
} from 'config/types/transaction'
import BonusService from 'services/BonusService'
import { formatToApiNetworkField, getChainCode } from 'utils'
import { getNetworkCodeType, isSolToken } from 'utils/token'
import { formatFitlerTransaction, getApiStatusTransaction } from 'utils/transaction'
import {
  BetTransactionFilters,
  OtherTransactionFilters,
  PaymentTransactionFilters,
  TipTransactionFilters,
} from 'views/Profile/Transactions/types'
import { HunnyPlayRequest } from './HunnyPlayRequest'
import {
  BalanceResponse,
  BaseResponse,
  HunnyRequest,
  Paging,
  PrepareSignMessageResponse,
  PrepareTokenNetworkPayload,
  PrepareTraditionalTipPayload,
  PrepareTraditionalWithdrawFiatPayload,
  PrepareWithdrawPayload,
  TraditionalSendTipPayload,
  TraditionalWithdrawFiatPayload,
  VerificationResponse,
} from './types'

class PaymentService extends HunnyPlayRequest {
  public getBalances(): HunnyRequest<BaseResponse<Paging<BalanceResponse>>> {
    const result = this._post(APIEndpointEnum.Balances, null, { authRequest: true })

    return result
  }

  public traditionalDeposit(
    params: {
      currency: string
      network: string
    },
    disabledToast = false,
  ): HunnyRequest<BaseResponse<{ address: string; minAmount: string }>> {
    const result = this._post(APIEndpointEnum.TraditionalDeposit, params, { authRequest: true, disabledToast })

    return result
  }

  public getWithdrawInfo({
    chainType,
    chainCode,
    currency,
  }: PrepareTokenNetworkPayload): HunnyRequest<BaseResponse<WithdrawInfo>> {
    const result = this._post(
      APIEndpointEnum.WithdrawInfo,
      {
        chain_type: chainType,
        chain_code: chainCode,
        currency,
      },
      { authRequest: true },
    )

    return result
  }

  public getDepositRollover(bonusListID: number[]): HunnyRequest<BaseResponse<DepositRolloverBase[]>> {
    const result = this._post(
      APIEndpointEnum.DepositRollover,
      {
        id_list: bonusListID,
      },
      { authRequest: true },
    )

    return result
  }

  public getTipInfo(params: { currency: string; network: string }): HunnyRequest<BaseResponse<LockAmountTipInfo>> {
    const { currency, network } = params
    const result = this._post(
      APIEndpointEnum.TipTinfo,
      {
        currency,
        network: formatToApiNetworkField(network),
      },
      { authRequest: true },
    )

    return result
  }

  public prepareTraditionalWithdraw(params: PrepareWithdrawPayload): HunnyRequest<BaseResponse<VerificationResponse>> {
    const payload: any = {
      chain_code: params.chainCode,
      to_address: params.toAddress,
      chain_type: params.chainType,
      currency: params.currency,
      fee: params.fee,
      value: params.value,
    }

    if ([getNetworkCodeType(ChainIdEnum.XRP), getNetworkCodeType(ChainIdEnum.TON)].includes(payload.chain_type)) {
      payload.comment = params.memo
    }

    const result = this._post(APIEndpointEnum.PrepareTraditionalWithdraw, payload, {
      authRequest: true,
      excludeErrors: [USER_LOCKED_WITHDRAW_ERROR_CODE],
    })
    return result
  }

  public prepareWalletWithdraw(
    value: string,
    account: string,
    currency: Token,
    memo: string,
  ): HunnyRequest<BaseResponse<PrepareSignMessageResponse>> {
    const payload: any = {
      value,
      to_address: account,
      currency: currency.code,
      chain_type: getNetworkCodeType(currency.network),
      chain_code: getChainCode(ChainIdEnum[currency.network]),
      chain_id: isSolToken(currency) ? null : currency.network,
    }

    if ([getNetworkCodeType(ChainIdEnum.XRP), getNetworkCodeType(ChainIdEnum.TON)].includes(payload.chain_type)) {
      payload.comment = memo
    }

    const result = this._post(APIEndpointEnum.PrepareWalletWithdraw, payload, {
      authRequest: true,
      excludeErrors: [USER_LOCKED_WITHDRAW_ERROR_CODE],
    })

    return result
  }

  public async traditionalWithdraw(params: PrepareWithdrawPayload): Promise<BaseResponse<string>> {
    const result = await this._request(
      APIEndpointEnum.TraditionalWithdraw,
      {
        chain_code: params.chainCode,
        chain_type: params.chainType,
        currency: params.currency,
        value: params.value,
        fee: params.fee,
        otp: params.otp,
        to_address: params.toAddress,
        verification_code: params.verificationCode,
        totp_auth_code: params.totpAuthCode,
      },
      { disabledToast: true, authRequest: true },
    )

    return result
  }

  public async walletWithdraw(sign: string, acceptMessage: any, totpAuthCode?: string): Promise<BaseResponse<string>> {
    let _payload = {
      accept_message: acceptMessage,
      message_signature: sign,
    }

    if (totpAuthCode) {
      _payload = Object.assign(_payload, { totp_auth_code: totpAuthCode })
    }

    const result = await this._request(APIEndpointEnum.WalletWithdraw, _payload, {
      excludeErrors: ['error_auth_totp'],
      authRequest: true,
    })
    return result
  }
  public async walletWithdrawFiat(
    sign: string,
    acceptMessage: any,
    totpAuthCode?: string,
  ): Promise<BaseResponse<WithdrawFiatTransaction>> {
    let _payload = {
      accept_message: acceptMessage,
      message_signature: sign,
    }

    if (totpAuthCode) {
      _payload = Object.assign(_payload, { totp_auth_code: totpAuthCode })
    }

    const result = await this._request(APIEndpointEnum.WalletWithdrawFiat, _payload, {
      excludeErrors: ['error_auth_totp'],
      authRequest: true,
    })
    return result
  }

  public getWithdrawal(code: string): HunnyRequest<BaseResponse<Transaction>> {
    const result = this._post(
      APIEndpointEnum.WithdrawalTransaction,
      {
        code,
      },
      { authRequest: true },
    )
    return result
  }

  public getWithdrawals(
    offset: number,
    limit: number,
    filterParams: PaymentTransactionFilters,
  ): HunnyRequest<BaseResponse<Paging<Transaction>>> {
    const fromTime = filterParams?.dateRange?.[0]?.getTime()
    const toTime = filterParams?.dateRange?.[1]?.getTime()

    const result = this._post(
      APIEndpointEnum.WithdrawalTransactions,
      {
        paging: {
          offset,
          limit,
        },
        filter: {
          currency: filterParams?.currency?.code || null,
          status: getApiStatusTransaction(filterParams?.status),
          network: formatToApiNetworkField(ChainIdEnum[filterParams?.currency?.network]) || null,

          from_time: fromTime > 0 ? Math.floor(fromTime / 1000) : null,
          to_time: toTime > 0 ? Math.floor(toTime / 1000) : null,
        },
      },
      { authRequest: true },
    )
    return result
  }

  public getDepositByTxnHash(txnHashCode: string): HunnyRequest<BaseResponse<Transaction>> {
    const result = this._post(APIEndpointEnum.GetDepositInfoByTxnHash, { txn_hash: txnHashCode }, { authRequest: true })
    return result
  }

  public getDeposit(hash: string, currency: string, network: ChainIdEnum): HunnyRequest<BaseResponse<Transaction>> {
    const result = this._post(
      APIEndpointEnum.DepositTransaction,
      {
        network: formatToApiNetworkField(ChainIdEnum[network]),
        txn_hash: hash,
        currency,
      },
      { authRequest: true },
    )

    return result
  }

  public getDeposits(
    offset: number,
    limit: number,
    filterParams: PaymentTransactionFilters,
  ): HunnyRequest<BaseResponse<Paging<{ item: Transaction; bonusInfo: UserBonus }>>> {
    const _source = this.AxiosCancelToken

    const call = async (): Promise<BaseResponse<Paging<{ item: Transaction; bonusInfo: UserBonus }>>> => {
      let depositResponse = await this._post(
        APIEndpointEnum.DepositTransactions,
        {
          paging: {
            offset,
            limit,
          },
          filter: formatFitlerTransaction(filterParams),
        },
        { authRequest: true },
      ).call()

      const depositBonusRecord: Record<string, UserBonus> = {}
      if (depositResponse?.data && !!depositResponse.data.items?.length) {
        const depositIds: number[] = depositResponse.data.items.reduce(
          (listDepositIds, currenntTransaction) => listDepositIds.concat(currenntTransaction.id),
          [],
        )

        const userDepositBonus = await BonusService.getUserDepositBonuses(depositIds).call()
        if (userDepositBonus.data && !!userDepositBonus.data.items.length) {
          userDepositBonus.data.items.forEach((bonusInfo) => {
            if (bonusInfo.depositId) depositBonusRecord[bonusInfo.depositId] = bonusInfo
          })
        }

        depositResponse = {
          ...depositResponse,
          data: {
            ...depositResponse.data,
            items: depositResponse.data.items.map((item: Transaction) => {
              return {
                id: item.id,
                item,
                bonusInfo: depositBonusRecord[item.id],
              }
            }),
          },
        }
      }

      return depositResponse
    }

    return {
      call,
      cancel: () => {
        _source.cancel()
      },
    }
  }

  public getDepositFiatBankMethodList({
    currency,
  }: {
    currency: string
  }): HunnyRequest<BaseResponse<[string, DepositFiatPaymentMethod[]][]>> {
    const result = this._post(
      APIEndpointEnum.DepositFiatBankMethodList,
      {
        currency,
      },
      { authRequest: true },
    )
    return result
  }
  public getWithdrawFiatBankMethodList({
    currency,
  }: {
    currency: string
  }): HunnyRequest<BaseResponse<[string, WithdrawFiatPaymentMethod[]][]>> {
    const result = this._post(
      APIEndpointEnum.WithdrawFiatBankMethodList,
      {
        currency,
      },
      { authRequest: true },
    )
    return result
  }

  public getWithdrawFiatLockBonusBalanceInfo(currency: string): HunnyRequest<BaseResponse<WithdrawInfo>> {
    const result = this._post(APIEndpointEnum.WithdrawFiatLockBonusBalanceInfo, { currency }, { authRequest: true })
    return result
  }

  public prepareTraditionalWithdrawFiat({
    payload: { bankAccountName, bankAccountNumber, bankCode, fee, selectedCurrency, value, accountMobileNumber },
  }: PrepareTraditionalWithdrawFiatPayload): HunnyRequest<BaseResponse<VerificationResponse>> {
    const result = this._post(
      APIEndpointEnum.PrepareTraditionalWithdrawFiat,
      {
        bank_account_name: bankAccountName,
        bank_account_number: bankAccountNumber,
        bank_code: bankCode,
        currency: selectedCurrency.code,
        fee: Number(fee),
        value: Number(value),
        account_mobile_number: accountMobileNumber || '',
      },
      { authRequest: true, excludeErrors: [USER_LOCKED_WITHDRAW_ERROR_CODE] },
    )

    return result
  }

  public prepareWalletWithdrawFiat({
    payload: { bankAccountName, bankAccountNumber, bankCode, fee, selectedCurrency, value, accountMobileNumber },
  }: PrepareTraditionalWithdrawFiatPayload): HunnyRequest<BaseResponse<PrepareSignMessageResponse>> {
    const result = this._post(
      APIEndpointEnum.PrepareWalletWithdrawFiat,
      {
        bank_account_name: bankAccountName,
        bank_account_number: bankAccountNumber,
        bank_code: bankCode,
        currency: selectedCurrency.code,
        fee: Number(fee),
        value: Number(value),
        account_mobile_number: accountMobileNumber || '',
      },
      { authRequest: true, excludeErrors: [USER_LOCKED_WITHDRAW_ERROR_CODE] },
    )

    return result
  }

  public async traditionalWithdrawFiat(
    params: TraditionalWithdrawFiatPayload,
  ): Promise<BaseResponse<FiatPaymentTransaction>> {
    const result = await this._request(
      APIEndpointEnum.TraditionalWithdrawFiat,
      {
        account_mobile_number: params.payload.accountMobileNumber || '',
        bank_account_name: params.payload.bankAccountName,
        bank_account_number: params.payload.bankAccountNumber,
        bank_code: params.payload.bankCode,
        currency: params.payload.selectedCurrency.code,
        value: params.payload.value,
        fee: params.payload.fee,
        otp: params.otp,
        verification_code: params.verificationCode,
        totp_auth_code: params.totpAuthCode,
      },
      { disabledToast: true, authRequest: true },
    )

    return result
  }

  public depositByFiat({
    amount,
    bankCode,
    currency,
  }: {
    amount: number
    bankCode: string
    currency: string
  }): Promise<BaseResponse<{ content: string; txnHash: string; pendingTransaction?: Transaction }>> {
    const result = this._request(
      APIEndpointEnum.FiatDeposit,
      { amount, bank_code: bankCode, currency },
      { authRequest: true },
    )
    return result
  }

  public resumeDepositFiat({
    txnHash,
  }: {
    txnHash: string
  }): Promise<BaseResponse<{ content: string; txnHash: string }>> {
    const result = this._request(APIEndpointEnum.ResumeDepositFiat, { tx_code: txnHash }, { authRequest: true })
    return result
  }

  public getBets(
    offset: number,
    limit: number,
    filterParams: BetTransactionFilters,
  ): HunnyRequest<BaseResponse<Paging<BetDetails>>> {
    const result = this._post(
      APIEndpointEnum.BetTransactions,
      {
        paging: {
          offset,
          limit,
        },
        filter: formatFitlerTransaction(filterParams),
      },
      {
        excludeErrors: ['error_feature_not_support'],
        authRequest: true,
      },
    )
    return result
  }

  public getRewards(
    offset: number,
    limit: number,
    filters?: OtherTransactionFilters,
  ): HunnyRequest<BaseResponse<Paging<RewardTransaction>>> {
    const result = this._post(
      APIEndpointEnum.RewardTransactions,
      {
        paging: {
          offset,
          limit,
        },
        filter: formatFitlerTransaction(filters),
      },
      { authRequest: true },
    )

    return result
  }

  public getBonuses(
    offset: number,
    limit: number,
    filterParams: OtherTransactionFilters,
  ): HunnyRequest<BaseResponse<Paging<BonusTransaction>>> {
    const result = this._post(
      APIEndpointEnum.BonusTransactions,
      {
        paging: {
          offset,
          limit,
        },
        filter: formatFitlerTransaction(filterParams),
      },
      { authRequest: true },
    )
    return result
  }

  public getTips(
    offset: number,
    limit: number,
    filterParams: TipTransactionFilters,
  ): HunnyRequest<BaseResponse<Paging<TipTransaction>>> {
    const result = this._post(
      APIEndpointEnum.TipTransactions,
      {
        paging: {
          offset,
          limit,
        },
        filter: {
          ...formatFitlerTransaction(filterParams),
          type: filterParams.tipType,
        },
      },
      { authRequest: true },
    )

    return result
  }

  public prepareWalletTip({
    amount,
    currency,
    message,
    uid,
    type,
  }: PrepareTraditionalTipPayload & { type?: string }): Promise<BaseResponse<PrepareSignMessageResponse>> {
    const result = this._request(
      APIEndpointEnum.PrepareWalletTip,
      {
        amount,
        currency: currency.code,
        chain_type: type,
        network: formatToApiNetworkField(ChainIdEnum?.[currency.network]),
        message,
        receiver_uid: uid,
      },
      { excludeErrors: ['error_auth_blocked'], authRequest: true },
    )

    return result
  }

  public async walletSendTip(
    acceptMessage: any,
    messageSignature: string,
    totpAuthcode?: string,
  ): Promise<BaseResponse<TipDetails>> {
    let _payload = {
      accept_message: acceptMessage,
      message_signature: messageSignature,
    }

    if (totpAuthcode) {
      _payload = Object.assign(_payload, {
        totp_auth_code: totpAuthcode,
      })
    }

    const result = await this._request(APIEndpointEnum.WalletSendTip, _payload, {
      excludeErrors: ['error_auth_totp'],
      authRequest: true,
    })

    return result
  }

  public prepareTraditionalTip({
    amount,
    uid,
    currency,
    message,
  }: PrepareTraditionalTipPayload): HunnyRequest<BaseResponse<VerificationResponse>> {
    const result = this._post(
      APIEndpointEnum.PrepareTraditionalTip,
      {
        network: formatToApiNetworkField(ChainIdEnum[currency.network]),
        receiver_uid: uid,
        currency: currency.code,
        amount,
        message,
      },
      { excludeErrors: ['error_auth_blocked'], authRequest: true },
    )
    return result
  }

  public async traditionalSendTip({
    amount,
    currency,
    message,
    uid,
    otp,
    verificationCode,
    totpAuthCode,
  }: TraditionalSendTipPayload): Promise<BaseResponse<TipDetails>> {
    const result = await this._request(
      APIEndpointEnum.TraditionalSendTip,
      {
        network: formatToApiNetworkField(ChainIdEnum[currency.network]),
        receiver_uid: uid,
        currency: currency.code,
        amount,
        message,
        otp,
        verification_code: verificationCode,
        totp_auth_code: totpAuthCode,
      },
      { disabledToast: true, authRequest: true },
    )

    return result
  }

  public getTipDetail(id: string): HunnyRequest<BaseResponse<TipDetails>> {
    const result = this._post(APIEndpointEnum.TipDetails, { id }, { authRequest: true })

    return result
  }

  public getSwaps(
    offset: number,
    limit: number,
    filters?: OtherTransactionFilters,
  ): HunnyRequest<BaseResponse<Paging<SwapTransaction>>> {
    const result = this._post(
      APIEndpointEnum.SwapTransactions,
      {
        paging: {
          offset,
          limit,
        },
        filter: formatFitlerTransaction(filters),
      },
      { authRequest: true },
    )

    return result
  }

  public async subscribeDeposit(token: Token): Promise<void> {
    const params = isSolToken(token)
      ? {
          network: formatToApiNetworkField(ChainIdEnum[token.network]),
          currency: token.code,
        }
      : {
          network: formatToApiNetworkField(ChainIdEnum[token.network]),
        }

    await this._request(APIEndpointEnum.SubscribeDeposit, params, { authRequest: true })
  }

  public async subscribeDepositByHash(
    currency: string,
    chainId: ChainIdEnum,
    txnHash: string,
  ): Promise<BaseResponse<{ id: number }>> {
    const response = await this._request(
      APIEndpointEnum.SubscribeDepositByHash,
      {
        currency,
        network: formatToApiNetworkField(ChainIdEnum[chainId]),
        txn_hash: txnHash,
      },
      { authRequest: true },
    )

    return response
  }

  public queryDepositByHash(id: number): HunnyRequest<BaseResponse<QueryDepositInfo>> {
    const result = this._post(APIEndpointEnum.QueryDepositByHash, { id }, { authRequest: true })

    return result
  }
}

const instance = new PaymentService()
export default instance
