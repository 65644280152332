import { APIEndpointEnum, BASE_API } from 'config/constants/server'
import { SentryHelper } from 'utils/sentryHelper'
import { delayed } from 'utils/index'
import { hunnyAxios } from './BaseRequest'
import { BaseResponse, LoginResponse } from './types'

export const TIME_REFRESH = 3.5 * 60 * 1000

export class AuthRefreshHelper {
  private static _isFetching = false
  private static _isWaitingInitialized = false

  private static _signedAtTime = 0
  private static _username = ''
  private static _uid = 0
  private static _deviceUid = ''
  private static _onRefreshFailed = null

  public static get IsInitialized() {
    return !!(this._username && this._uid && this._deviceUid)
  }

  public static get IsSigned() {
    return this._signedAtTime + TIME_REFRESH > Date.now()
  }

  public static init = (payload: {
    onRefreshFailed: (res?: LoginResponse) => void
    username: string
    uid: number
    deviceUid: string
  }) => {
    SentryHelper.addBreadcrumbs({
      category: 'authRefreshHelper.init',
      message: 'AuthRefreshHelper init data',
    })
    this._isWaitingInitialized = false
    this._onRefreshFailed = payload.onRefreshFailed
    this._username = payload.username
    this._uid = payload.uid
    this._deviceUid = payload.deviceUid
  }

  public static reset = () => {
    SentryHelper.addBreadcrumbs({
      category: 'authRefreshHelper.reset',
      message: 'AuthRefreshHelper reset data',
    })

    this._signedAtTime = 0
    this._username = ''
    this._uid = 0
    this._deviceUid = ''
    this._onRefreshFailed = null
  }

  public static captureSignedAtTime = () => {
    if (!this.IsInitialized) {
      this._isWaitingInitialized = true
    }

    SentryHelper.addBreadcrumbs({
      category: 'authRefreshHelper.captureSignedAtTime',
      message: 'AuthRefreshHelper update signed time',
    })

    this._signedAtTime = Date.now()
    localStorage.setItem('jwtrtime', this._signedAtTime.toString())
  }

  public static async refresh() {
    this._isFetching = true
    let result: null | BaseResponse<LoginResponse> = null

    try {
      const _refresh = async () =>
        hunnyAxios.post(
          APIEndpointEnum.Refresh,
          {
            address: this._username,
            device_uid: this._deviceUid,
            uid: this._uid,
          },
          {
            baseURL: BASE_API,
            withCredentials: true,
          },
        )
      const res = await _refresh()
      result = res?.data

      if (result?.code !== 'success' && result?.code !== 'error_auth') {
        // retry call refresh

        SentryHelper.addBreadcrumbs({
          category: 'authRefreshHelper.retryRefresh',
          message: `AuthRefreshHelper meet ${result?.code} error code and retry call refresh`,
        })

        await delayed(2000)
        const retriedRes = await _refresh()
        result = retriedRes?.data
      }

      SentryHelper.addBreadcrumbs({
        category: 'authRefreshHelper.refreshCall',
        message: `Server Refresh Call API [${result?.code}]`,
        level: result?.code === 'success' ? 'info' : 'error',
      })

      if (result?.code === 'success') {
        this.captureSignedAtTime()
      } else {
        if (result?.code !== 'error_auth') {
          const storedSignedAtTime = Number(localStorage.getItem('jwtrtime'))
          const margin = Date.now() - storedSignedAtTime

          SentryHelper.captureFeatureClientError({
            feature: 'Auth Refresh Helper',
            error: new Error(`Server Refresh Call API Failed error: ${result?.code}`),
            options: {
              payload: {
                error: result?.code,
                current: Date.now(),
                signedAtTime: this._signedAtTime,
                dayMargin: margin / (1000 * 60 * 60 * 24),
                storedSignedAtTime,
                username: this._username,
                uid: this._uid,
                deviceUild: this._deviceUid,
              },
            },
          })
        }

        if (this._onRefreshFailed) this._onRefreshFailed(result)

        this.reset()
      }
    } catch (e) {
      SentryHelper.captureFeatureClientError({
        feature: 'Auth Refresh Helper',
        error: e,
      })
    }

    this._isFetching = false
    return result
  }

  private static waitingRefreshDone() {
    return new Promise((resolve) => {
      const loop = () => (!this._isFetching ? resolve(true) : setTimeout(loop, 100))
      loop()
    })
  }

  private static waitingInitializedDone() {
    return new Promise((resolve) => {
      const loop = () => (!this._isWaitingInitialized ? resolve(true) : setTimeout(loop, 100))
      loop()
    })
  }

  public static buildRequestFn<E>(
    call: () => Promise<BaseResponse<E>>,
    title?: string,
  ): () => Promise<BaseResponse<E>> {
    return async () => {
      if (this._isWaitingInitialized) {
        await this.waitingInitializedDone()
      }

      if (!this.IsInitialized) {
        const message = `Call ${title} Failed, because Refresh helper is not initialized`

        SentryHelper.captureFeatureClientError({
          feature: 'Auth Refresh Helper',
          error: new Error(message),
        })

        return call()
      }
      if (!this.IsSigned) {
        await this._prepareAuth()
      }

      if (this.IsInitialized) {
        let response = await call()

        if (response?.code === 'error_auth') {
          SentryHelper.addBreadcrumbs({
            category: 'authRefreshHelper.retryRefresh',
            message: `Call ${title} Failed, retry again.`,
          })
          await this._prepareAuth()
          if (this.IsSigned) response = await call()
        }

        if (response?.code === 'error_auth') {
          const message = `Call ${title} Failed, because JWT expired or something error related JWT`
          SentryHelper.captureFeatureClientError({
            feature: 'Auth Refresh Helper',
            error: new Error(message),
          })
        }
        return response
      }

      return call()
    }
  }

  private static async _prepareAuth() {
    if (this._isFetching) {
      await this.waitingRefreshDone()
    } else {
      await this.refresh()
    }
  }
}
