import { CountryEnum } from 'config/constants/country'
import { MetaTag } from 'config/types/metatag'
import { BaseResponse } from 'services/types'

export const MetaTagMapper = (rawResponse: string): BaseResponse<MetaTag> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)
  if (!response?.data || response.code !== 'success') {
    return {
      code: 'success',
      message: response?.message,
      data: { notfound: true },
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      title: response.data.title,
      description: response.data.description,
      ogImage: response.data.og_image_url,
      ogTitle: response.data.og_title,
      ogDescription: response.data.og_description,
      twitterImage: response.data.twitter_image_url,
      twitterTitle: response.data.twitter_title,
      twitterDescription: response.data.twitter_description,
      keyword: response.data.keyword,
      htmlContent: response.data.html_description,
    },
  }
}

export const ClientCountryMapper = (rawRes: string): BaseResponse<CountryEnum> => {
  const response: BaseResponse<any> = JSON.parse(rawRes)
  if (!response?.data || response.code !== 'success') {
    return {
      code: 'network_error',
      message: response?.message,
      data: null,
    }
  }
  return {
    code: response.code,
    message: response.message,
    data: response.data?.country_code,
  }
}
